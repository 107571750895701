// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-challenge-winners-challenge-winners-json-slug-js": () => import("./../../../src/pages/challenge-winners/{ChallengeWinnersJson.slug}.js" /* webpackChunkName: "component---src-pages-challenge-winners-challenge-winners-json-slug-js" */),
  "component---src-pages-challenges-challenges-json-slug-js": () => import("./../../../src/pages/challenges/{ChallengesJson.slug}.js" /* webpackChunkName: "component---src-pages-challenges-challenges-json-slug-js" */),
  "component---src-pages-challenges-index-js": () => import("./../../../src/pages/challenges/index.js" /* webpackChunkName: "component---src-pages-challenges-index-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-how-to-compete-js": () => import("./../../../src/pages/how-to-compete.js" /* webpackChunkName: "component---src-pages-how-to-compete-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-markdown-remark-frontmatter-slug-js": () => import("./../../../src/pages/{MarkdownRemark.frontmatter__slug}.js" /* webpackChunkName: "component---src-pages-markdown-remark-frontmatter-slug-js" */),
  "component---src-pages-resources-js": () => import("./../../../src/pages/resources.js" /* webpackChunkName: "component---src-pages-resources-js" */),
  "component---src-pages-results-js": () => import("./../../../src/pages/results.js" /* webpackChunkName: "component---src-pages-results-js" */),
  "component---src-pages-sponsors-js": () => import("./../../../src/pages/sponsors.js" /* webpackChunkName: "component---src-pages-sponsors-js" */)
}

